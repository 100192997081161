// extracted by mini-css-extract-plugin
export var heroBackgroundInnerCircleGreen = "SingleAuthor-module--heroBackgroundInnerCircleGreen--ab739";
export var introSectionContainer = "SingleAuthor-module--introSectionContainer--88ee0";
export var postDate = "SingleAuthor-module--postDate--44760";
export var postItem = "SingleAuthor-module--postItem--133cd";
export var postTitle = "SingleAuthor-module--postTitle--72e7b";
export var singleAuthorAbout = "SingleAuthor-module--singleAuthorAbout--1d7b9";
export var singleAuthorBlogs = "SingleAuthor-module--singleAuthorBlogs--bed45";
export var singleAuthorPosts = "SingleAuthor-module--singleAuthorPosts--da44e";
export var singleAuthors = "SingleAuthor-module--singleAuthors--f45bb";
export var singleAuthorsContent = "SingleAuthor-module--singleAuthorsContent--858d7";