// extracted by mini-css-extract-plugin
export var AuthorIntroContainer = "AuthorIntro-module--AuthorIntroContainer--b0bdb";
export var authorGithub = "AuthorIntro-module--authorGithub--8d8ac";
export var authorImage = "AuthorIntro-module--authorImage--b3b43";
export var authorImageContainer = "AuthorIntro-module--authorImageContainer--65888";
export var backgroundCircles = "AuthorIntro-module--backgroundCircles--8254e";
export var contactButton = "AuthorIntro-module--contactButton--dde79";
export var heroBackgroundCircleLeft = "AuthorIntro-module--heroBackgroundCircleLeft--b87a1";
export var heroBackgroundInnerCircleLeft = "AuthorIntro-module--heroBackgroundInnerCircleLeft--5d3cc";
export var heroWhiteBlurCirce = "AuthorIntro-module--heroWhiteBlurCirce--c3e45";
export var introDescriptionButtonSection = "AuthorIntro-module--introDescriptionButtonSection--8cbde";
export var introSectionContainer = "AuthorIntro-module--introSectionContainer--db839";
export var introSectionOuter = "AuthorIntro-module--introSectionOuter--0c30c";
export var introSectionText = "AuthorIntro-module--introSectionText--27b79";